<template>
  <div class="home">
    <Landing />
    <Problem />
    <Responsive />
    <Everywhere />
    <Description />
    <Demo />
    <PricePrev />
    <Steps />
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from '../components/LandingSection.vue'
import Problem from '../components/Problem.vue'
import Responsive from '../components/ResponsiveSection'
import Everywhere from '../components/Everywhere'
import Description from '../components/Description'
import Demo from '../components/Demo'
import PricePrev from '../components/PricePreview'
import Steps from '../components/Steps'

export default {
  name: 'Home',
  components: {
    Landing,
    Problem,
    Responsive,
    Everywhere,
    Description,
    Demo,
    PricePrev,
    Steps
  },
    mounted () {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  },
}
</script>
