import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/demo',
    name: 'Demo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Demo.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "about" */ '../components/Faq.vue')
  },
  {
    path: '/schulen',
    name: 'Für Schulen',
    component: () => import(/* webpackChunkName: "about" */ '../views/Schools.vue')
  },
  {
    path: '/konzept',
    name: 'Konzept',
    component: () => import(/* webpackChunkName: "about" */ '../views/Concept.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "about" */ '../components/Impressum.vue')
  },
  {
    path: '/agb',
    name: 'AGB',
    component: () => import(/* webpackChunkName: "about" */ '../components/AGB.vue')
  },
  {
    path: '/start',
    name: 'Startpunkt',
    component: () => import(/* webpackChunkName: "about" */ '../components/Start.vue')
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "about" */ '../components/Privacy.vue')
  },
  {
    path:'/*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  scrollBehavior: function(to) {
    if (to.hash) {
        return {selector: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
},
  routes
})

export default router
