<template>
  <div class="pPreviewWrapper">
    <div class="top">
      <img src="../assets/images/designs/up-convex.svg" alt="" />
    </div>
    <div class="content">
      <h2>Unser Angebot für Sie</h2>
      <h3>leamos im Monats- oder Jahresabo</h3>
      <div class="priceCardContainer">
        <div class="priceCard">
          <img
            style="width: 100px"
            src="../assets/images/logos/leamosLogo.svg"
            alt="leamos - Lesetrainer"
          />
          <h4 style="margin-top: 5px">Leamos - Premium</h4>
          <!-- <p>Abonnement: <strong>monatlich</strong></p> -->
          <p>Preis: <br /><strong>5,80€</strong>/Monat</p>
          <a href="https://app.leamos.at/registrierung/1IK4xFIHCsSlwSpgsds62M6e" target="_blank"><button class="buy-btn">Leamos monatlich abonnieren</button></a>
          <a href="https://app.leamos.at/registrierung/1IK4xFIHCsSlwSpgsds62M6e" target="_blank"><button class="trial-btn">Jetzt 14 Tage kostenlos testen!</button></a>
        </div>
        <div class="priceCard">
          <img
            style="width: 100px"
            src="../assets/images/logos/leamosLogo.svg"
            alt="leamos - Lesetrainer"
          />
          <h4 style="margin-top: 5px">Leamos - Premium</h4> 
          <!-- <p>Abonnement: <strong>monatlich</strong></p> -->
          <p>Preis: <br /><strong>56€</strong>/Jahr</p>
          <div class="trapezoid">20% Ersparniss</div>
          <a href="https://app.leamos.at/registrierung/1IK4vwIHCsSlwSpgCNiwJB1i" target="_blank"><button class="buy-btn">Leamos jährlich abonnieren</button></a>
          <a href="https://app.leamos.at/registrierung/1IK4vwIHCsSlwSpgCNiwJB1i" target="_blank"><button class="trial-btn">Jetzt 14 Tage kostenlos testen!</button></a>
        </div>
      </div>
      <!-- <div class="coupon">
        <h1>leamos wird ab dem 15.12.2020 verfügbar sein:</h1>
        <h2>
          Willst du benachrichtigt werden und dir einen Coupon für bis zu -50%* sichern?
        </h2>
        <form
          action="/"
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
        <input type="hidden" name="form-name" value="contact">
          <p>
            <label>Dein Name: <input type="text" name="name" /></label>
          </p>
          <p>
            <label
              >Deine Email: <input type="email" name="email" required
            /></label>
          </p>
          <p>
            <button
              type="submit"
              style="
                cursor: pointer;
                background-color: #f5f5f5;
                outline: none;
                border: 0px;
                padding: 5px 10px;
                border-radius: 5px;
                box-shadow: 0 0 10px 2px lightgray;
              "
            >
              Coupon sichern!
            </button>
          </p>
        </form>
        <p style="font-size: 0.7rem">
          *50% gültig auf die ersten drei Monate bei einem Monats-Abo.
        </p>
      </div> -->

      <div class="text">
        <h3 style="text-align: center">Wir fassen nochmal zusammen – das bringt Dir leamos:</h3>
        <ul>
          <li>wahlweise auf allen Geräten</li>
          <li>wenig Zeitaufwand</li>
          <li>viel Abwechslung</li>
          <li>sehr motivierend</li>
          <li>Training für bessere Wortbilder</li>
          <li>gutes Gedächtnistraining</li>
          <li>effektives Aufmerksamkeitstraining</li>
        </ul>
        <router-link to="/konzept">
        <div style="
                cursor: pointer;
                background-color: #f5f5f5;
                outline: none;
                border: 0px;
                padding: 5px 10px;
                border-radius: 5px;
                box-shadow: 0 0 10px 2px lightgray;

              ">  <h4 style="color: black; margin: 4px; text-align: center;">
          Mehr erfahren
          </h4> </div>
       </router-link
        >
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PricePrev",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";

.coupon {
  margin-top: 25px;
  background-color: #47c94d;
  padding-top: 20px;
  border-radius: 20px;
  h1 {
    color: rgb(255, 222, 77);
  }
  input {
    border: 1px solid lightgray;
    outline: none;
    box-shadow: inset 0 0 10px lightgrey;
    border-radius: 5px;
    padding: 5px 10px;
  }
}
.pPreviewWrapper {
  background-color: #b9eabb;
  .top {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .priceCardContainer {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      .priceCard {
        background-color: #f5f5f5;
        padding: 10px 10px;
        border-radius: 5px;
        width: 150px;
        box-shadow: 1px 1px 3px lightgray;
        transition: all 0.5s ease-out;
        // cursor: pointer;
        border: solid 1px #8383837a;
        box-shadow: 1px 1px 3px 3px #8383833d;
        background-color: white;
        border-radius: 5px;
        padding: 20px 3%;
        // max-width: 45%;
        width: 40%;
        max-width: 300px;
        text-align: left;
        margin: auto;
        // transform: scale(1);
        transition: all 0.5s ease-out;
        position: relative;
        @media screen and (min-width: $start-mobile) {
          &:hover {
            transform: scale(1.1);
          }
        }
        .buy-btn{
            // cursor: pointer;
            background-color: rgb(245, 245, 245);
            outline: none;
            border: 0px;
            padding: 5px 10px;
            border-radius: 5px;
            background-color: #b9eabb;
            // &:hover{
            //   background-color: #47c94d;
            //   color: white;
            // }
        }
        .trial-btn{
          margin-top: 25px;
          background-color: #ffd000;
          width: 100%;
          padding: 10px ;
          border-radius: 5px;
          font-weight: 800;
          box-shadow: 1px 1px 5px  rgba(107, 106, 106, 0.8);
          outline: none;
          border: 0px;
          cursor: pointer;
        }
        .trapezoid {
          border-bottom: 30px solid #ffd000;
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
          height: 0;
          width: 75px;
          position: absolute;
          right: -31px;
          top: 21px;
          text-align: center;
          transform: rotate(45deg);
          font-size: 0.8rem;
        }
      }
    }
    .text {
      align-self: center;
      text-align: left;
    }
  }

  .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
}
</style>
