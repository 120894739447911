<template>
  <div class="summaryWrapper">
    <div class="bottomBg">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
    <div class="content">
      <!-- <h1>Drei gute Gründe für leamos</h1> -->
      <p>
        <!-- Wer kennt nicht die riesige Anstrengung von Leseschwachen verbunden mit
        Frustration und Widerwillen beim täglichen Üben. Lesetraining bedeutet
        immer und immer wieder den gleichen Text zu lesen. Durch Homeschooling
        vergrößerten sich die Probleme. -->
        Für manche bedeutet Lesen eine große Freude, für andere ist es eine große Anstrengung. Für einige ist Lesen mit Frustration und Abneigung verbunden, denn Texte müssen täglich immer und immer wieder gelesen werden.  leamos hilft allen, Freude am Lesen lernen zu finden!
      </p>
      <p>
        <!-- Hier setzt leamos an und bietet Abhilfe. Ein neuartiges Konzept des
        Lesetrainings: -->
        leamos ist ein neuartiges Lesetraining, das jedem Einzelnen einen spielerischen Zugang zum Lesen bietet. Das Konzept baut auf diesen Bausteinen auf:
      </p>
      <table>
        <tr>
          <td class="letter">L</td>
          <td>esekompetenz täglich überall trainieren</td>
        </tr>
        <tr>
          <td class="letter">E</td>
          <td>infach erreichen</td>
        </tr>
        <tr>
          <td class="letter">A</td>
          <td>llein oder mit Partner</td>
        </tr>
        <tr>
          <td class="letter">M</td>
          <td>otiviert und modern</td>
        </tr>
        <tr>
          <td class="letter">O</td>
          <td>ptimiert auf das Auge</td>
        </tr>
        <tr>
          <td class="letter">S</td>
          <td>chule und zu Hause</td>
        </tr>
      </table>
      <!-- <div class="reason">
            <div class="number">
                <img src="../assets/images/motivation.svg" alt="">
            </div>
            <div class="text">
                <h3>Belohnungssystem</h3>
                <p>Mit Motivation und Freude lesen trainieren mit leamos. Sammle Diamanten und Lesepunkte und erweitere täglich deinen Streak.</p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/user.svg" alt="">
            </div>
            <div class="text">
                <h3>Trainingsart</h3>
                <p>Lautes oder leises Lesen trainieren mit leamos. Trainiere alleine acht verschiedene Arten des Einzeltrainings. Mit einem Lesepartner im betreutem Lesen wird lautes Lesen trainiert. Die vielen verschiedenen Trainingsarten verbessern Aufmerksamkeit, Leseflüssigkeit und Visualisierung.</p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/buttons.svg" alt="">
            </div>
            <div class="text">
                <h3>Abwechslung</h3>
                <p>Variantenreich trainiert leamos mit Worten, Pseudoworten und Symbolen die unterschiedlichen Verarbeitungswege im Gehirn. Oftmalige Wiederholung bei gleichzeitiger Abwechslung hilft die Plastizität des Gehirns zu verbessern.</p>
            </div>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Problem",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.summaryWrapper {
  position: relative;
  width: 100vw;
  min-height: 300px;
  background-color: $leamos-green-light;
  .content {
    padding-top: 1px;
    padding-bottom: 80px;
    width: 70vw;
    margin: auto;
    z-index: 2;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    table {
      margin: auto;
      margin-bottom: 20px;
      td {
        text-align: left;
      }
      .letter {
        font-weight: 700;
        text-align: right;
      }
    }
    //   .reason{
    //       display: flex;
    //       text-align: left;
    //       width: 60%;
    //       @media screen and (max-width: 800px) {
    //                 width: 100%;
    //             }
    //       .number{
    //           font-size: 6rem;
    //           font-weight: bolder;
    //           display: flex;
    //           align-items: center;
    //         //   width: 150px;
    //         //   max-height: 200px;
    //         //   max-width: 200px;

    //           img{
    //             //   height: 100%;
    //               width: 100%;
    //           }
    //       }
    //       .text{
    //           padding-left: 20px;
    //           max-width: 85%;
    //           min-width: 200px;
    //       }
    //       &:nth-of-type(2){
    //           margin-left: auto;
    //           @media screen and (max-width: 800px) {
    //                 margin-left: 0px;
    //             }
    //       }
    //   }
    //   color: white;
  }
  .bottomBg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 1;
    img {
      width: 100%;
      height: 90px;
    }
  }
}
</style>
