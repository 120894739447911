<template>
  <div class="sectionWrapper">
      <div class="top">
      <img src="../assets/images/designs/up-convex.svg" alt="" />
    </div>
    <div class="content">
      
      <div class="text">
        <h2 style="margin-bottom: 0px;">Jederzeit und Überall</h2>
        <h3 style="margin-top: 0px;">Zeitfenster effektiv nutzen</h3>
        <p>
Lesen trainieren auch bei Wartezeiten, in Bahn, Bus oder Auto, auf dem Weg zum Sport oder bei Langeweile. Nutze die Zeit effektiv und werde zum Lesemeister!         </p>
      </div>
      <div class="image">
        <img src="../assets/images/leamos_im_auto.jpg" alt="" />
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Everywhere",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #b9eabb;
  .top {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
   .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .image {
      display: flex;
      align-items: center;
      width: 70%;
      max-width: 500px;
      @media screen and (max-width: $small-pre-mobile) {
        width: 50%;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      img {
        // max-width: 500px;
        // width: 100%;
        max-height: 250px;
        margin: auto;

            box-shadow: 1px 1px 7px #2c3e50;
    border-radius: 8px;
    margin-bottom: 10px;
      }
    }
    .text {
      width: calc(100% - 500px);
      @media screen and (max-width: $small-pre-mobile) {
        width: 50%;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      text-align: center;
          display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    }
  }
}
</style>
