<template>
  <div class="sectionWrapper">
    <div class="content">
      <div class="image">
        <img src="../assets/images/responsive.png" alt="" />
      </div>
      <div class="text">
        <h2 style="margin-bottom: 0px;">Verwendbar auf Handy, Tablet, Notebook oder PC</h2>
        <h3 style="margin-top: 0px;">komplett variabel & ständiger Wechsel möglich</h3>
        <p>
          <!-- Handy, Tablett, Notebook oder PC – komplett variabel,  ständiger Wechsel möglich. -->
          leamos kann ohne Installation jederzeit auf allen Endgeräten zum Einsatz kommen. leamos baut auf der neuesten Programmiertechnik auf. Lege gleich auf jedem Bildschirmgerät unter <a href="https://app.leamos.at" target="_blank">app.leamos.at</a>  los und baue den Leseerfolg auf.
        </p>
        <!-- <h2>
          Ist leamos ein Spiel?
        </h2>
        <p>
         
          leamos ist ein spielerisches Training. leamos ist für Personen, die Schwierigkeiten bei der Lesegeschwindigkeit, Lesegenauigkeit, Aufmerksamkeit und Visualisierung haben. 
        </p>
        <h2>Für wen wurde leamos entwickelt?</h2>
        <p>Erwachsene wie Kinder können mit leamos ihre Lesefertigkeiten verbessern. Selbst bei einer diagnostizierten Legasthenie
          oder eine Lese-Rechtschreibschwäche kann es als Unterstützung eingesetzt werden. </p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Responsive",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #f5f4f4bb;
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
      .image {
        display: flex;
        align-items: center;
        width: 70%;
        max-width: 500px;
        @media screen and (max-width: $small-pre-mobile) {
          width: 50%;
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        img {
          // max-width: 500px;
          width: 100%;
        }
      }
      .text {
        width: calc(100% - 500px);
        @media screen and (max-width: $small-pre-mobile) {
          width: 50%;
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        text-align: justify;
      }
  }
}
</style>
