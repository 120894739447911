<template>
  <div class="sectionWrapper">
    <div class="content">
      <div class="reason">
            <div class="number">
                <img src="../assets/images/buttons.svg" alt="">
            </div>
            <div class="text">
                <h2 style="margin-bottom: 0px;">Farblos</h2>
                <h3 style="margin-top: 0px;">damit das Gehirn sich auf das Wichtige konzentriert</h3>
                <p>
                    Voller Fokus auf die Leseübungen – ohne Ablenkung und  Abdriften in die Traumwelt.	
                </p>
            </div>
        </div>
      <div class="reason">
          <div class="number">
              <img src="../assets/images/fast.svg" alt="">
          </div>
          <div class="text">
              <h2 style="margin-bottom: 0px;">In 15 min zum Erfolg</h2>
              <h3 style="margin-top: 0px;">kurzes Trainieren reicht, um weiter zu kommen</h3>
              <p>
                  Kurze tägliche Trainingseinheiten bringen Verbesserungen. Der Erfolg kann schon in dieser kurzen Zeit gemessen werden. 
              </p>
          </div>
      </div>
      <div class="reason">
          <div class="number">
              <img src="../assets/images/motivation.svg" alt="">
          </div>
          <div class="text">
               <h2 style="margin-bottom: 0px;">Selbstständig aber nicht allein</h2>
                <h3 style="margin-top: 0px;">gemeinsam den Lesererfolg verfolgen und feiern</h3>
                <p>
                    leamos stellt dem „Lesesportler“ individuelle Trainings zusammen. Diamanten, Feuer und Lesepunkte zeigen den Nutzern, ihren Betreuern und Eltern den täglichen Fleiß und Erfolg.         
                </p>	
          </div>
      </div>
      <div class="reason">
          <div class="number">
              <img src="../assets/images/Dash_Web.svg" alt="">
          </div>
          <div class="text">
               <h2 style="margin-bottom: 0px;">Motivation und Freude</h2>
                <h3 style="margin-top: 0px;">mit einer Vielfalt an Übungen wird es nicht langweilig</h3>
                <p>
                   Neun verschiedene Trainingsarten trainieren vielseitig. Der Zeiger des Tachocharts rückt mit jedem Training dem täglichen Ziel näher und motiviert zum Weitermachen.
                </p>	
          </div>
      </div>
      <div class="reason">
          <div class="number">
              <img src="../assets/images/infinity.svg" alt="">
          </div>
          <div class="text">
           <h2 style="margin-bottom: 0px;">Ein riesiges Übungsangebot</h2>
        <h3 style="margin-top: 0px;">so gibt es immer neue Herausforderungen</h3>
        <p>
           Der leamos Algorithmus funktioniert mit über 1780 Lesetrainingslisten die auf deutschen Sprachstrukturen abgestimmt sind. Insgesamt werden in leamos über 6000 Worte und 4000 Pseudoworte verwendet. 
        </p>
          </div>
      </div>
      <!-- <div class="text">
        <img src="../assets/images/buttons.svg" alt="">
        <h2 style="margin-bottom: 0px;">Farblos</h2>
        <h3 style="margin-top: 0px;">damit das Gehirn sich auf das Wichtige konzentriert</h3>
        <p>
            Voller Fokus auf die Leseübungen – ohne Ablenkung und  Abdriften in die Traumwelt.	
        </p>
      </div> -->
      <!-- <div class="text">
          <img src="../assets/images/fast.svg" alt="">
        <h2 style="margin-bottom: 0px;">In 15 min zum Erfolg</h2>
        <h3 style="margin-top: 0px;">kurzes Trainieren reicht, um weiter zu kommen</h3>
        <p>
            Kurze tägliche Trainingseinheiten bringen Verbesserungen. Der Erfolg kann schon in dieser kurzen Zeit gemessen werden. 
        </p>
      </div> -->
      <!-- <div class="text">
          <img src="../assets/images/motivation.svg" alt="">
        <h2 style="margin-bottom: 0px;">Selbstständig aber nicht allein</h2>
        <h3 style="margin-top: 0px;">gemeinsam den Lesererfolg verfolgen und feiern</h3>
        <p>
            leamos stellt dem „Lesesportler“ individuelle Trainings zusammen. Diamanten, Feuer und Lesepunkte zeigen den Nutzern, ihren Betreuern und Eltern den täglichen Fleiß und Erfolg.         
        </p>	
      </div> -->
      <!-- <div class="text">
          <img src="../assets/images/Dash_Web.svg" alt="">
        <h2 style="margin-bottom: 0px;">Motivation und Freude</h2>
        <h3 style="margin-top: 0px;">mit einer Vielfalt an Übungen wird es nicht langweilig</h3>
        <p>
           Neun verschiedene Trainingsarten trainieren vielseitig, Der Zeiger des Tachocharts rückt mit jedem Training dem täglichen Ziel näher und motiviert zum Weitermachen.
        </p>
      </div> -->
      <!-- <div class="text">
        <img src="../assets/images/infinity.svg" alt="">
        <h2 style="margin-bottom: 0px;">Ein riesiges Übungsangebot</h2>
        <h3 style="margin-top: 0px;">so gibt es immer neue Herausforderungen</h3>
        <p>
           Der leamos Algorithmus funktioniert mit über 1780 – auf die deutschen Sprachstrukturen abgestimmte Lesetrainingslisten, die auf das individuelle Level abgestimmt durchtrainiert werden. Insgesamt werden in leamos über 6000 Worte und 4000 Pseudoworte verwendet. 
        </p>
      </div> -->
      <!-- <div class="image">
        <img src="../assets/images/leamos_im_auto.jpg" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Description",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #f5f4f4bb;
  .content {
    width: 70vw;
    margin: auto;
    // display: flex;
    // flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    // .image {
    //   display: flex;
    //   align-items: center;
    //   width: 70%;
    //   max-width: 500px;
    //   @media screen and (max-width: $small-pre-mobile) {
    //     width: 50%;
    //   }
    //   @media screen and (max-width: 800px) {
    //     width: 100%;
    //   }
    //   img {
    //     // max-width: 500px;
    //     // width: 100%;
    //     max-height: 250px;
    //     margin: auto;
    //     box-shadow: 1px 1px 3px lightgray;
    //     border-radius: 8px;
    //   }
    // }
    .reason{
          display: flex;
          text-align: left;
          width: 60%;
          @media screen and (max-width: 800px) {
                    width: 100%;
                }
          .number{
              font-size: 6rem;
              font-weight: bolder;
              display: flex;
              align-items: center;
              // width: 150px;
              // max-height: 200px;
              // max-width: 200px;
              width: 25%;
              img{
                //   height: 100%;
                  width: 100%;
              }
          }
          .text{
              padding-left: 20px;
              max-width: 75%;
              min-width: 200px;
              @media screen and (max-width: 800px) {
              &:nth-of-type(even){
                padding-right: 20px;
                padding-left: 0px;
              }
              }
              // &:nth-of-type(odd){
              //   padding-left: 20px;
              // }

          }
          &:nth-of-type(even){
              margin-left: auto;
              @media screen and (max-width: 800px) {
                    margin-left: 0px;
                    flex-direction: row-reverse;
                }
          }
      }
    // .text {
    //   width: calc(100% - 500px);
    //   @media screen and (max-width: $small-pre-mobile) {
    //     width: 50%;
    //   }
    //   @media screen and (max-width: 800px) {
    //     width: 100%;
    //   }
    //   text-align: center;
    //   img{
    //       max-height: 150px;
    //   }
    
    // }
    // .text:nth-child(even){
    //     margin-left: auto;
    //     text-align: center;
    // }    
  }
}
</style>
