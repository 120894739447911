<template>
  <div id="app">
    <Navbar />
    <router-view class="router"/>
    <Footer />
    <Sidebar />
    <!-- <div style="height: 1000px"></div> -->
    <!-- <div class="construction">
      <img src="./assets/images/construction.svg" alt="">
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import Sidebar from './components/Sidebar'

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer,
    Sidebar
  },
    mounted() {
    window.addEventListener("scroll", function () {
      const animatedEls = document.getElementsByClassName('animate')
      const viewHeight = window.scrollY + window.innerHeight
      animatedEls.forEach( el =>{
        var animationOffset = 100
        const additionalOffset = el.getAttribute('data-animation-offset')
        if(additionalOffset) { animationOffset += (additionalOffset*1)}
        if(el.offsetTop < (viewHeight-animationOffset)) {
          el.classList.add('animated')
        }
      })
    })
    }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&family=Ubuntu:wght@400;500;700&display=swap');

#app {
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f5f5;
}
router-link, a{
  text-decoration: none;
}
.construction{
  height: 300px;
  z-index: 100;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.animate{
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.animated{
  opacity: 1;
  &[data-animation="downUp"]{
    animation: downUp 0.5s;
  }
  &[data-animation="leftRight"]{
    animation: leftRight 0.5s;
  }
  &[data-animation="rightLeft"]{
    animation: rightLeft 0.5s;
  }
  &[data-animation-delay="100"]{
    animation-delay: 100ms;
    transition-delay: 100ms;
  }
  &[data-animation-delay="200"]{
    animation-delay: 200ms;
    transition-delay: 200ms;
  }
  &[data-animation-delay="300"]{
    animation-delay: 300ms;
    transition-delay: 300ms;
  }
  &[data-animation-delay="400"]{
    animation-delay: 400ms;
    transition-delay: 400ms;
  }
}

@keyframes downUp {
  0% { transform: translate3d(0, 100px, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes leftRight {
  0% { transform: translate3d(-200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes rightLeft {
  0% { transform: translate3d(200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
// .router{
//   margin-top: 100px;
// }

// #nav {
//   padding: 30px;
// }

// #nav a {
//   font-weight: bold;
//   color: #2c3e50;
// }

// #nav a.router-link-exact-active {
//   color: #42b983;
// }
</style>
