<template>
<div class="sidebarWrapper" id="sidebar">
    <div style="padding: 0 30px">
      <h1 style="text-align: left" v-on:click="toggleSidebar()">&times;</h1>
      <div class="menu">
        <img src="../assets/images/logos/leamosLogo.svg" alt="" />
        <router-link class="link" to="/"
          ><h2 v-on:click="toggleSidebar()">Übersicht</h2></router-link
        >
         <div class="devider"></div>
        <router-link class="link" to="/konzept"
          ><h2 v-on:click="toggleSidebar()">Konzept</h2></router-link
        >
        <div class="devider"></div>
        <router-link class="link" to="/schulen"
          ><h2 v-on:click="toggleSidebar()">Für Schulen</h2></router-link
        ><!--
        <div class="devider"></div>
        <router-link to="/demo"
          ><h2 v-on:click="toggleSidebar()">Demo</h2></router-link
        > -->
        <div class="devider"></div>
        <router-link class="link" to="/faq"
          ><h2 v-on:click="toggleSidebar()">FAQ</h2></router-link
        >
        <!-- <div class="devider"></div>
        <router-link to="/contacto"
          ><h2 v-on:click="toggleSidebar()">Contacto</h2></router-link
        > -->
        <div style="margin-top: 30px;">
          <a class="link" href="https://app.leamos.at/login" target="_blank" role="button">
            <button class="appBtn">
              <h2 style="margin: 0;">Login</h2>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  methods: {
    toggleSidebar: function () {
      document.getElementById("sidebar").classList.toggle("active");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";
.sidebarWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: -100vw;
  top: 0;
  background-color: white;
  transition: all 0.5s;
  z-index: 100;
  &.active {
    right: 0;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 100px;
    }
    h2 {
      margin: 10px 0;
    }
    .devider {
      width: 60px;
      border-radius: 5px;
      height: 3px;
      background-color: $leamos-green;
    }
  }
  .link:visited{
color: $leamos-green;
  }
  .link{
    &.is-active{
          color: $dark-blue-color;
        }
  }
  .appBtn {
      border-radius: 100px;
      border: 2px solid $leamos-green;
      padding: 5px 20px;
      background-color: white;
      h2 {
        color: $leamos-green;
        // font-family: Sulphur Point;
        font-weight: 700;
      }
      // @media screen and (max-width: $start-mobile) {
      //   display: none;
      // }
    }
    // &.scrolled .appBtn {
    //   background-color: transparent;
    // }
}
</style>