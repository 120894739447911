<template>
  <div class="demoWrapper">
      <div class="content">
          <h1>Probier`s aus</h1>
          <h2>wie funktioniert leamos jetzt genau?</h2>
        Hier ein Einblick, wie ein Training mit leamos aussieht. Such` eine Aufgabenart und Schwierigkeitsstufe aus. Los geht’s! 
            <h3>Probiere: Reihenfolge und Schreibweise merken</h3>
            <p>Achte auf das kurz aufblitzende Pseudowort, Symbol oder Wort, merke es dir und suche das Richtige aus den vier angezeigten Möglichkeiten heraus.</p>
                <!-- <p>
                    Wie lange sollen die Worte oder die Symbole sichtbar sein? 
                </p> -->
          <div class="demoWrapper" id="demoWrapper01">             
              <div class="hide">
                  <img src="../assets/images/icons/gps_fixed-24px.svg" alt="">
              </div>
              <div class="show" id="show0">
                  <span style="line-height: 24px;" v-if="demo01.rep<5 || demo01.rep>6">{{demo01.currShow}}
                      <!-- <img v-bind:key="icon" v-for="icon in demos[0].values[demos[0].rep]" v-bind:src="icon" alt=""> -->
                  </span>
                  <span style="line-height: 24px;" v-else>
                      <span v-bind:key="icon" v-for="icon in demo01.currShow"><i style="margin-right:3px;" :class="'fas ' + 'fa-' + icon"></i></span>
                      <!-- <img v-bind:key="icon" v-for="icon in demos[0].values[demos[0].rep]" v-bind:src="icon" alt=""> -->
                  </span>
              </div>
              <div class="answer" id="answer0">
                  <span v-if="demo01.rep<5 || demo01.rep>6">
                      <span v-bind:key="option" v-for="(option, index) in demo01.currOptions">
                           <button  @click="checkAnswer(option)">{{option}}</button>
                           <br v-if="index==1">
                      </span>
                   
                  </span>
                  <span v-else>
                      <span v-bind:key="option" v-for="(option, index) in demo01.currOptions" >
                          <button @click="checkAnswer(option)">
                        <span v-bind:key="icon" v-for="icon in option"><i style="margin-right:3px;" :class="'fas ' + 'fa-' + icon"></i></span>
                    </button>
                    <br v-if="index==1">
                      </span>
                  </span>
                  
                  <!-- <button>Test</button> -->
              </div>
              <div class="result" id="correct0">
                  Das war richtig :)
                  <img style="width:48px;" src="@/assets/images/icons/check-24px.svg" alt="">
                    <button @click="next()">Weiter</button>
              </div>
              <div class="result" id="false0">
                  Leider falsch :(
                      <img style="width:48px;" src="@/assets/images/icons/clear-24px.svg" alt="">
                    <button @click="next()">Weiter</button>
              </div>
              <div class="over" id="over0">
                  Fertig!
                  Du kannst Leamos 14 Tage kostenlos testen
                  <button class="trial-btn">Jetzt 14 Tage testen!</button>
                  <button @click="reset01()" class="redo-btn"><i class="fas fa-redo"></i> Nochmals</button>
              </div>
              <div class="menu active" id="menu0">
                  Welche Aufblitzzeit möchtest du probieren? 
                  <div>
                        <button v-on:click="startDemo01(250)">250 ms</button>
                        <button v-on:click="startDemo01(600)">600 ms</button>
                        <button v-on:click="startDemo01(1200)">1200 ms</button>
                  </div>
              </div>

          </div>
          <h3>Probiere: Merkmal erkennen</h3>
          <p>Welches der beiden Merkmale war in dem angezeigten Wort enthalten?</p>
          <!-- <p>Wie lange soll das Wort aufblitzen? </p> -->
          <div class="demoWrapper" id="demoWrapper02">             
              <div class="hide">
                  <img src="../assets/images/icons/gps_fixed-24px.svg" alt="">
              </div>
              <div class="show" id="show1">
                  <span style="line-height: 24px;" >
                      {{demo02.currShow}}
                  </span>
              </div>
              <div class="answer" id="answer1">
                <span v-bind:key="option" v-for="option in demo02.currOptions">
                    <button  @click="checkAnswer02(option.correct)">{{option.value}}</button>
                </span>
              </div>
              <div class="result" id="correct1">
                  Das war richtig :)
                  <img style="width:48px;" src="@/assets/images/icons/check-24px.svg" alt="">
                    <button @click="next02()">Weiter</button>
              </div>
              <div class="result" id="false1">
                  Leider falsch :(
                      <img style="width:48px;" src="@/assets/images/icons/clear-24px.svg" alt="">
                    <button @click="next02()">Weiter</button>
              </div>
              <div class="over" id="over1">
                  <div>
                      Fertig!
                  Du kannst Leamos 14 Tage kostenlos testen
                  </div>
                  <button class="trial-btn">Jetzt 14 Tage testen!</button>
                  <button @click="reset02()" class="redo-btn"><i class="fas fa-redo"></i> Nochmals</button>
              </div>
              <div class="menu active" id="menu1">
                  <div>
                      Welche Aufblitzzeit möchtest du probieren? 
                  <div>
                        <button v-on:click="startDemo02(250)">250 ms</button>
                        <button v-on:click="startDemo02(600)">600 ms</button>
                        <button v-on:click="startDemo02(1200)">1200 ms</button>
                  </div>
                  </div>
              </div>

          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Demo',
  data() {
    return {
        icons:[
            '../assets/images/icons/bug_report-24px.svg',
            '../assets/images/icons/coronavirus-24px.svg',
            '../assets/images/icons/masks-24px.svg',
            '../assets/images/icons/pet-24px.svg'
        ],
        demos: [
            {
                values: [],
        rep: 0,
        currShow: null,
        options: [],
            }
        ],
        display_time: 300,
        demo01:{
            currShow: null,
            rep: 0,
            currOptions: null,
            display_time: 300,
            reps: [
            {
                value: 'kop',
                options: ['Kop', 'kap', 'kop', 'Kap']
            },
            {
                value: 'monko',
                options: ['nonko', 'Monko', 'nanko', 'monko']
            },
            {
                value: 'zulter',
                options: ['zulter', 'Monko', 'zulten', 'zultem']
            },
            {
                value: 'quikater',
                options: ['quikader', 'quikater', 'quigater', 'quikatre']
            },
            {
                value: 'debagosi',
                options: ['debakosi', 'debagosie', 'debagosi', 'debagossi']
            },
            {
                value: ['square', 'play', 'circle'],
                options: [['play', 'square', 'circle'], ['play', 'circle', 'square'], ['circle', 'play', 'square'], ['square', 'play', 'circle']]
            },
            {
                value: ['cat', 'crow', 'dog'],
                options: [['crow', 'cat', 'dog'], ['cat', 'crow', 'dog'], ['cat', 'dog', 'crow'], [ 'dog','cat', 'crow']]
            },
            {
                value: 'grüßen',
                options: ['grüssen', 'Grüßen', 'güßen', 'grüßen']
            },
            {
                value: 'Arzt',
                options: ['Artzt', 'Arzt', 'arzt', 'Arzd']
            },
            {
                value: 'plötzlich',
                options: ['plötzlich', 'plözlich', 'plötzlick', 'plöztlich']
            },
        ]
        },
        demo02:{
            currShow: null,
            rep: 0,
            currOptions: null,
            reps: [
            {
                value: 'nack',
                options: [
                    {value:'ck', correct: true}, 
                    {value:'ch', correct: false}]
            },
            {
                value: 'nickos',
                options: [
                    {value:'ck', correct: true}, 
                    {value:'ch', correct: false}]
            },
            {
                value: 'nechterek',
                options: [
                    {value:'ck', correct: false}, 
                    {value:'ch', correct: true}]
            },
            {
                value: 'pommisin',
                options: [
                    {value:'n', correct: true}, 
                    {value:'nn', correct: false}]
            },
            {
                value: 'wesannum',
                options: [
                    {value:'n', correct: false}, 
                    {value:'nn', correct: true}]
            },
            {
                value: 'Papier',
                options: [
                    {value:'ie', correct: true}, 
                    {value:'ei', correct: false}]
            },
            {
                value: 'Streik',
                options: [
                    {value:'ie', correct: false}, 
                    {value:'ei', correct: true}]
            },
            {
                value: 'spielten',
                options: [
                    {value:'ie', correct: true}, 
                    {value:'ei', correct: false}]
            },
        ]
        },
    };
  },
  methods: {
      delay: function(ms) { return new Promise(resolve => setTimeout(resolve, ms)); },
      generateDemo01: function(){
           for(let i = 0; i < 5; i++){
                let iconCopy = this.icons.slice()
                let selection = []
                for(let y = 0; y < 4; y++){
                    let rand = Math.floor(Math.random()*iconCopy.length)
                    console.log(Math.floor(Math.random()*iconCopy.length), iconCopy.length)
                    selection.push(iconCopy[rand])
                    iconCopy.splice(rand,1)
                }
                this.demos[0].values.push(selection)
           }
           
           for(let i = 0; i < 5; i++){
               this.demos[0].options.push([])
               let alarm = 0
                do {
                    let iconCopy = this.icons.slice()
                    let selection = []
                    for(let y = 0; y < 4; y++){
                        let rand = Math.floor(Math.random()*iconCopy.length)
                        selection.push(iconCopy[rand])
                    iconCopy.splice(rand,1)
                    }
                    if(!JSON.stringify(this.demos[0].options[i]).includes(JSON.stringify(selection))){
                        this.demos[0].options[i].push(selection)
                    }
                    alarm++
                } while(this.demos[0].options[i].length<4 && alarm<100);
            }   
            console.log(this.demos[0].options)   
        },
        async showRep01 (){
            await this.delay(1000)
            this.demo01.currShow = this.demo01.reps[this.demo01.rep].value
            this.demo01.currOptions =this.demo01.reps[this.demo01.rep].options
            document.getElementById('show0').classList.add('active')
            await this.delay(this.demo01.display_time)
            document.getElementById('show0').classList.remove('active')
            document.getElementById('answer0').classList.add('active')
            // await this.delay(10000)
            // document.getElementById('answer0').classList.remove('active')
        },
        checkAnswer (value){
            console.log(JSON.stringify(value), JSON.stringify(this.demo01.reps[this.demo01.rep].value))
            if(JSON.stringify(value)==JSON.stringify(this.demo01.reps[this.demo01.rep].value)){
                document.getElementById('correct0').classList.add('active')
            } else {
                document.getElementById('false0').classList.add('active')
            }
            document.getElementById('answer0').classList.remove('active')
            this.demo01.rep++
        },
        next(){
            document.getElementById('correct0').classList.remove('active')
            document.getElementById('false0').classList.remove('active')
            if(this.demo01.rep==this.demo01.reps.length){
                document.getElementById('over0').classList.add('active')
            } else {
                this.showRep01()
                
            }
            
        },
        startDemo01(ms){
            this.demo01.display_time = ms
            document.getElementById('menu0').classList.remove('active')
            this.showRep01()
    
        },
         async showRep02 (){
            await this.delay(1000)
            this.demo02.currShow = this.demo02.reps[this.demo02.rep].value
            this.demo02.currOptions =this.demo02.reps[this.demo02.rep].options
            document.getElementById('show1').classList.add('active')
            await this.delay(this.demo02.display_time)
            document.getElementById('show1').classList.remove('active')
            document.getElementById('answer1').classList.add('active')
            // await this.delay(10000)
            // document.getElementById('answer0').classList.remove('active')
        },
        checkAnswer02 (value){
            if(value){
                document.getElementById('correct1').classList.add('active')
            } else {
                document.getElementById('false1').classList.add('active')
            }
            document.getElementById('answer1').classList.remove('active')
            this.demo02.rep++
        },
        next02(){
            document.getElementById('correct1').classList.remove('active')
            document.getElementById('false1').classList.remove('active')
            if(this.demo02.rep==this.demo02.reps.length){
                document.getElementById('over1').classList.add('active')
            } else {
                this.showRep02()
                
            }
            
        },
        startDemo02(ms){
            this.demo02.display_time = ms
            document.getElementById('menu1').classList.remove('active')
            this.showRep02()
    
        },
        reset01(){
            this.demo01.rep = 0
            document.getElementById('over0').classList.remove('active')
            document.getElementById('menu0').classList.add('active')
        },
        reset02(){
            this.demo02.rep = 0
            document.getElementById('over1').classList.remove('active')
            document.getElementById('menu1').classList.add('active')
        }

      },
    //   showRep: function(demo){
          
    //   }

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
    .demoWrapper{
        .content{
            width: 70vw;
            margin: auto;
            margin-top: 100px;
            // display: flex;
          
                .demoWrapper{
                    font-size: 1.2rem;
                    max-width: 300px;
                    width: 100%;
                    min-width: 250px;
                    height: 250px;
                    background-color: white;
                    border-radius: 6px;
                    box-shadow: 1px 1px 5px ;
                    margin: auto;
                    position: relative;
                    .hide{
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding-top: 45px;
                        padding-bottom: 15px;
                        background-color: transparent;
                        width: 100%;
                    }
                    .show{
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding-top: 45px;
                        background-color: white;
                        border-radius: 6px;
                        width: 100%;
                        // display: flex;
                        text-align: center;
                        display: none;
                        font-size: 1.5rem;
                        img{
                            height: 24px;
                            width: 24px;
                        }
                        &.active{
                            display: block;
                        }
                    }
                    .answer{
                        position: absolute;
                        top: 84px;
                        width: 100%;
                        display: none;
                        &.active{
                            display: block;
                        }
                        button{
                            background-color: white;
                            border-radius: 5px;
                            padding: 5px 10px;
                            border: none;
                            box-shadow: 1px 1px 3px lightgray;
                            margin: 10px;
                            font-size: 1.2rem;
                        }
                    }
                    .result{
                        position: absolute;
                        top: 45px;
                        left: 10%;
                        height: 100px;
                        width: calc(80% - 40px );
                        background-color: white;
                        box-shadow: 1px 1px 6px lightgray;
                        padding: 20px;
                        border-radius: 7px;
                        display: none;
                        flex-direction: column;
                        align-items: center;
                        // background-color: #b9eabb;
                        &.active{
                            display: flex;
                        }
                        button{
                            // background-color: white;
                            background-color: #f5f5f5;
                            border-radius: 5px;
                            padding: 5px 10px;
                            border: none;
                            box-shadow: 1px 1px 3px lightgray;
                            margin: 5px;

                        }
                    }
                    .over{
                        // position: absolute;
                        // height: 100%;
                        // width: 100%;
                        // background-color: white;
                        // display: none;
                        // border-radius: 7px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: calc(100% - 40px);
                        width: calc(100% - 40px);
                        background-color: white;
                        box-shadow: 1px 1px 6px lightgray;
                        padding: 20px;
                        border-radius: 7px;
                        display: none;
                        flex-direction: column;
                        background-color: #b9eabb;
                         &.active{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                            .trial-btn{
                            margin-top: 25px;
                            background-color: #ffd000;
                            width: 100%;
                            padding: 10px ;
                            border-radius: 5px;
                            font-weight: 800;
                            box-shadow: 1px 1px 5px  rgba(107, 106, 106, 0.8);
                            outline: none;
                            border: 0px;
                            cursor: pointer;
                            }
                        .redo-btn{
                        margin-top: 15px;
                        background-color: #f3f2f2;
                        color: rgb(112, 112, 112);
                        width: fit-content;
                        padding: 5px ;
                        border-radius: 5px;
                        font-weight: 600;
                        box-shadow: 1px 1px 5px  rgba(107, 106, 106, 0.8);
                        outline: none;
                        border: 0px;
                        cursor: pointer;
                        }
                    }
                    .menu{
                       position: absolute;
                        top: 0;
                        left: 0;
                        height: calc(100% - 40px);
                        width: calc(100% - 40px);
                        background-color: white;
                        box-shadow: 1px 1px 6px lightgray;
                        padding: 20px;
                        border-radius: 7px;
                        display: none;
                        flex-direction: column;
                        // background-color: #b9eabb;
                        &.active{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        button{
                            // background-color: white;
                            background-color: #f5f5f5;
                            border-radius: 5px;
                            padding: 5px 10px;
                            border: none;
                            box-shadow: 1px 1px 3px lightgray;
                            margin: 5px;
                        }
                    }
                }
            
        }
    }
</style>
