<template>
  <div>
    <div class="navBg">
      <img
        src="../assets/images/designs/down-concave.svg"
        alt="leamos - Lesetrainer"
      />
    </div>
    <div class="navWrapper">
      <nav id="nav" class="nav">
        <router-link to="/" style="margin-top: 0px">
          <svg
            class="logo"
            id="logo"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 145.311 50"
            enable-background="new 0 0 145.311 50"
            xml:space="preserve"
          >
            <g>
              <path
                d="M19.812,30.743V4.435c0,0,1.563-0.187,2.461,0.637c0.897,0.824,1.018,2.045,1.018,2.045v23.625H19.812z"
              />
              <path
                d="M30.649,22.356c0,0.633,0.23,1.292,0.692,1.979c0.461,0.686,0.996,1.266,1.602,1.74c1.108,0.87,2.4,1.306,3.877,1.306
                            c1.498,0,2.817-0.461,3.96-1.384c0,0,1.317-0.99,2.495-1.042s2.289,0.804,2.289,0.804c-1.028,1.662-2.294,2.941-3.798,3.837
                            c-1.503,0.897-3.152,1.346-4.946,1.346c-1.345,0-2.624-0.258-3.837-0.771c-1.213-0.515-2.281-1.232-3.204-2.156
                            c-0.924-0.923-1.643-1.991-2.157-3.204c-0.514-1.214-0.771-2.493-0.771-3.837c0-1.346,0.257-2.632,0.771-3.858
                            s1.232-2.3,2.157-3.225c0.896-0.922,1.958-1.634,3.184-2.136c1.227-0.5,2.512-0.751,3.857-0.751c1.346,0,2.631,0.251,3.857,0.751
                            c1.226,0.501,2.289,1.213,3.185,2.136c1.951,2.005,2.927,4.327,2.927,6.964c0,0.475-0.04,0.976-0.119,1.503H30.649z M33.774,14.958
                            c-0.95,0.475-1.708,1.101-2.275,1.879c-0.567,0.778-0.85,1.603-0.85,2.473h12.343c0-0.87-0.283-1.694-0.85-2.473
                            c-0.567-0.778-1.325-1.404-2.274-1.879c-0.95-0.475-1.966-0.712-3.047-0.712S34.724,14.484,33.774,14.958z"
              />
              <path
                d="M69.934,13.799v16.944h-2.73v-3.64c-1.819,2.559-4.246,3.838-7.278,3.838c-1.82,0-3.496-0.441-5.025-1.325
                            c-1.53-0.884-2.736-2.091-3.62-3.62c-0.884-1.53-1.325-3.191-1.325-4.984c0-1.82,0.441-3.495,1.325-5.025
                            c0.884-1.529,2.09-2.735,3.62-3.62c1.529-0.884,3.205-1.325,5.025-1.325c1.872,0,3.408,0.389,4.608,1.167
                            c1.201,0.778,2.09,1.615,2.67,2.512v-3.244c0,0,1.239-0.121,1.994,0.665C70.027,13.008,69.934,13.799,69.934,13.799z
                            M63.563,27.281c1.108-0.646,1.985-1.522,2.631-2.63c0.646-1.108,0.969-2.321,0.969-3.64c0-1.319-0.323-2.539-0.969-3.66
                            c-0.646-1.12-1.523-2.004-2.631-2.65c-1.107-0.646-2.321-0.97-3.639-0.97c-1.32,0-2.54,0.324-3.66,0.97
                            c-1.122,0.646-2.005,1.53-2.651,2.65c-0.646,1.122-0.97,2.341-0.97,3.66c0,1.319,0.323,2.531,0.97,3.64
                            c0.646,1.107,1.529,1.984,2.651,2.63c1.12,0.647,2.34,0.97,3.66,0.97C61.243,28.251,62.456,27.929,63.563,27.281z"
              />
              <path
                d="M96.598,11.932c1.107,0.646,1.984,1.53,2.63,2.65c0.646,1.121,0.969,2.341,0.969,3.66v12.501h-2.689V18.242
                            c0-1.267-0.449-2.341-1.345-3.225c-0.897-0.883-1.966-1.326-3.205-1.326c-1.266,0-2.342,0.442-3.225,1.326
                            c-0.884,0.884-1.326,1.945-1.326,3.185v0.04v12.501h-2.73V18.203c0-1.24-0.448-2.301-1.345-3.185
                            c-0.896-0.883-1.964-1.326-3.205-1.326c-1.24,0-2.308,0.456-3.204,1.365c-0.897,0.91-1.345,1.998-1.345,3.264v12.422h-2.69V11.477
                            c0,0,1-0.134,1.75,0.556c0.559,0.515,0.667,1.305,0.667,1.305c0.528-0.633,1.479-1.452,2.31-1.822
                            c0.831-0.369,1.667-0.554,2.512-0.554c1.188,0,2.301,0.277,3.344,0.831c1.041,0.554,1.892,1.292,2.551,2.215
                            c0.686-0.922,1.549-1.661,2.591-2.215c1.041-0.554,2.157-0.831,3.343-0.831C94.276,10.963,95.49,11.286,96.598,11.932z"
              />
              <path
                d="M109.929,30.149c-1.213-0.526-2.281-1.252-3.204-2.175c-0.923-0.924-1.649-1.991-2.176-3.205
                            c-0.528-1.213-0.791-2.492-0.791-3.837c0-1.372,0.263-2.677,0.791-3.917c0.527-1.239,1.253-2.32,2.176-3.244
                            c0.923-0.922,1.991-1.641,3.204-2.155c1.213-0.515,2.493-0.771,3.838-0.771c1.372,0,2.677,0.257,3.917,0.771
                            c1.239,0.514,2.32,1.233,3.244,2.155c0.923,0.924,1.641,2.005,2.155,3.244c0.515,1.241,0.771,2.546,0.771,3.917
                            c0,1.345-0.257,2.624-0.771,3.837c-0.515,1.214-1.232,2.281-2.155,3.205c-0.924,0.923-2.005,1.648-3.244,2.175
                            c-1.24,0.529-2.545,0.792-3.917,0.792C112.421,30.941,111.142,30.679,109.929,30.149z M110.622,13.257
                            c-0.989,0.422-1.866,1.016-2.631,1.781c-0.765,0.765-1.365,1.661-1.8,2.69c-0.435,1.028-0.653,2.096-0.653,3.205
                            c0,1.107,0.218,2.162,0.653,3.165c0.435,1.002,1.035,1.886,1.8,2.649c0.765,0.766,1.642,1.365,2.631,1.801
                            c0.989,0.435,2.037,0.652,3.146,0.652c1.107,0,2.176-0.218,3.205-0.652c1.028-0.436,1.911-1.035,2.65-1.801
                            c0.765-0.764,1.358-1.647,1.78-2.649c0.421-1.003,0.633-2.058,0.633-3.165c0-1.108-0.218-2.176-0.653-3.205
                            c-0.435-1.029-1.021-1.925-1.76-2.69c-0.739-0.765-1.622-1.358-2.65-1.781c-1.028-0.421-2.097-0.633-3.205-0.633
                            C112.659,12.624,111.61,12.835,110.622,13.257z"
              />
              <path
                d="M133.311,30.941c-2.295,0-4.074-0.594-5.341-1.78c-0.845-0.817-1.411-1.819-1.701-3.007h1.938
                            c0.317,0.977,0.937,1.721,1.859,2.235c0.923,0.514,1.964,0.771,3.125,0.771c1.504,0,2.73-0.382,3.68-1.147
                            c0.87-0.712,1.306-1.596,1.306-2.65c0-1.478-0.515-2.479-1.543-3.007c-0.845-0.396-2.084-0.593-3.72-0.593
                            c-1.608,0-2.782-0.165-3.52-0.495c-0.739-0.329-1.372-0.785-1.899-1.365c-0.818-0.975-1.227-2.136-1.227-3.481
                            c0-0.896,0.185-1.747,0.554-2.551c0.369-0.804,0.897-1.444,1.583-1.919c1.187-0.817,2.729-1.226,4.628-1.226
                            c1.608,0,2.954,0.316,4.036,0.949c0.738,0.422,1.385,1.055,1.938,1.898c0.396,0.634,0.672,1.306,0.83,2.018h-1.938
                            c-0.158-0.527-0.316-0.91-0.475-1.147c-0.791-1.213-2.175-1.86-4.154-1.939c-1.214,0-2.176,0.146-2.888,0.435
                            c-0.712,0.316-1.266,0.778-1.661,1.385c-0.396,0.607-0.594,1.266-0.594,1.978c0,0.396,0.053,0.751,0.158,1.069
                            c0.317,1.161,1.002,1.898,2.058,2.215c0.5,0.185,1.062,0.291,1.681,0.316c0.619,0.026,0.982,0.04,1.088,0.04
                            c1.029,0.053,1.86,0.132,2.493,0.237c0.633,0.105,1.292,0.33,1.978,0.673c1.636,0.844,2.453,2.36,2.453,4.549
                            c-0.026,1.451-0.607,2.717-1.74,3.798C137.029,30.361,135.368,30.941,133.311,30.941z"
              />
            </g>
            <path
              id="bookPath"
              fill="#FFFFFF"
              d="M41.41,42.34c-5.846-1.329-11.771-2.646-17.739-3.273c-1.495-0.157-3.029-0.131-4.577,0.022
                        c1.929-0.416,3.869-0.652,5.772-0.543c4.904,0.283,9.807,1.061,14.663,1.783c1.782,0.266,2.549-2.457,0.751-2.725
                        c-5.111-0.761-10.256-1.535-15.414-1.885c-1.12-0.075-2.256-0.03-3.396,0.096c0.626-0.157,1.254-0.308,1.885-0.442
                        c4.875-1.044,10.466-0.516,15.361,0.271c0.891,0.145,1.273-1.218,0.375-1.362c-4.863-0.783-10.058-1.162-14.946-0.471
                        c-3.533,0.5-7.03,1.683-10.426,2.846c1.133-3.367,2.147-6.821,2.694-10.323c0.768-4.911,0.075-10.328-0.293-15.272
                        c-0.067-0.903-1.48-0.91-1.413,0c0.373,4.993,1.042,10.426,0.267,15.385c-0.143,0.917-0.325,1.832-0.534,2.742
                        c0.207-1.436,0.31-2.865,0.256-4.268c-0.198-5.102-1.167-10.251-1.923-15.295c-0.271-1.797-2.993-1.031-2.726,0.75
                        c0.709,4.723,1.478,9.477,1.807,14.243c0.154,2.232-0.162,4.564-0.692,6.883c0.201-1.62,0.298-3.232,0.239-4.815
                        c-0.222-5.908-2.009-11.986-3.363-17.72c-0.627-2.66-4.714-1.531-4.087,1.126c1.258,5.337,2.728,10.824,3.192,16.296
                        c0.282,3.319-0.682,6.839-1.384,10.052c-0.539,2.473-1.142,4.936-1.808,7.377c-0.424,1.551,1.046,3.047,2.607,2.607
                        c5.251-1.48,11.229-3.587,16.734-3.156c5.699,0.445,11.425,1.892,16.99,3.156C42.945,47.031,44.075,42.945,41.41,42.34z"
            />
          </svg>
        </router-link>
        <div class="nav-container">
          <router-link to="/" class="menu-link"
            ><span ><span>Übersicht</span></span></router-link
          >
          <router-link to="/konzept" class="menu-link followers"
            ><span 
              ><span>Konzept</span></span
            ></router-link
          >

          <router-link to="/schulen" class="menu-link followers">
            <span ><span>Für Schulen</span></span>
          </router-link>
          <!-- 
          <router-link to="/demo">
            <span class="menu-link followers"><span>Demo</span></span>
          </router-link> -->
          <router-link to="/faq" class="menu-link followers"
            ><span 
              ><span>FAQ</span></span
            ></router-link
          >
        </div>
        <!-- just for now the following to have the space-between work -->
        <div></div>
        <div class="appBtn">
          <a href="https://app.leamos.at/login" role="button">
            <h2 style="margin: 0; font-size: 1.5rem">Login</h2>
          </a>
        </div>
        <a class="mobile-menu" id="" role="button" v-on:click="toggleSidebar()">
          <!-- <i class="fas fa-bars"></i> -->
          <img src="../assets/images/icons/menu-24px.svg" alt="Sidebar Menu" />
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    toggleSidebar: function () {
      document.getElementById("sidebar").classList.toggle("active");
    },
  },
  mounted() {
    window.addEventListener("scroll", function () {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("nav").classList.add("scrolled");
        document.getElementById("bookPath").style.fill = "#47C94D";
        document.getElementById("logo").style.width = "150px";
      } else {
        document.getElementById("nav").classList.remove("scrolled");
        document.getElementById("logo").removeAttribute("style");
        document.getElementById("bookPath").style.fill = "#FFFFFF";
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.navBg {
  height: 180px;
  background-color: #f5f5f5;
  img {
    height: 100%;
    width: 100vw;
  }
}
.navWrapper {
  position: absolute;
  left: 0;
  top: 0;
  .nav {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 12;
    height: 80px;
    transition: 0.5s;
    position: fixed;
    padding: 0 15% 0 15%;
    justify-content: space-between;
    &.scrolled {
      background-color: #f5f4f4;
      box-shadow: 1px 1px 10px lightgray;
      height: 80px;
      @media screen and (max-width: $start-mobile) {
        height: 50px;
      }
      .logo {
        // transition:  0.5s ease-out;
        margin-top: 0px;
        @media screen and (max-width: $start-mobile) {
          height: 30px;
        }
      }
      .nav-container {
        .menu-link{
           color: $dark-blue-color;
        //     &.followers {
        //   border-left: 1px solid $dark-blue-color;
        // }
        &.is-active{
          color: #47C94D;
        }
        &:hover{
          color: #47C94D;
        } 
        }
      }

    }
    @media screen and (max-width: $small-pre-mobile) {
      width: 80%;
      padding: 0 10% 0 10%;
    }
    @media screen and (max-width: $start-mobile) {
      width: 90%;
      padding: 0 5% 0 5%;
      height: 70px;
    }

    .logo {
      //   height: $nav-content-height;
      margin-top: 15px;
      transition: all 0.5s ease-out;
      width: 200px;
    }
    .nav-container {
      height: $nav-content-height;
      line-height: $nav-content-height;
      vertical-align: middle;
      .menu-link {
        padding: 0 15px 0 15px;
        font-family: "Ubuntu", sans-serif;
        font-size: 1.1em;
        font-weight: 500;
        text-decoration: none;
        color: #f5f4f4;
        // &.followers {
        //   border-left: 1px solid #f5f4f4;
        // }
        &.is-active{
          color: $dark-blue-color;
        }
      }
      .menu-link:hover {
        color: $dark-blue-color;
      }
      @media screen and (max-width: $start-mobile) {
        display: none;
      }
    }
    .appBtn {
      border-radius: 100px;
      border: 2px solid $leamos-green;
      padding: 5px 20px;
      background-color: white;
      h2 {
        color: $leamos-green;
        // font-family: Sulphur Point;
        font-weight: 700;
      }
      @media screen and (max-width: $start-mobile) {
        display: none;
      }
    }
    &.scrolled .appBtn {
      background-color: transparent;
    }
    .mobile-menu {
      display: none;
      @media screen and (max-width: $start-mobile) {
        display: block;
      }
    }
  }
}
</style>
